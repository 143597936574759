import { useRef, useMemo } from 'react';

import i18n from 'src/locales/i18n';
import { IJob, JobFilters, JobsResponse } from 'src/services/jobs/jobs.types';
import { ApplicationStage } from 'src/services/applications/applications.types';
import {
  useGetMyActiveJobsQuery,
  useGetOrganisationJobsQuery,
} from 'src/services/jobs/jobs.service';

import { TenantType } from 'src/types/enums';
import { CardType, IKanbanColumn, IKanbanJobCard } from 'src/types/kanban';

// ----------------------------------------------------------------------

export const KanbanColorMap = {
  [ApplicationStage.INVITED]: '#4a4a4a',
  [ApplicationStage.INTERESTED]: '#4a4a4a', // Yellow
  [ApplicationStage.INTERNAL_INTERVIEW]: '#76A9FA',
  [ApplicationStage.NO_APPLICATIONS]: '#76A9FA', // Blue
  [ApplicationStage.APPLIED]: '#76A9FA', // Blue
  [ApplicationStage.SCREENING]: '#76A9FA',
  [ApplicationStage.READY_TO_SHORTLIST]: '#84E1BC',
  [ApplicationStage.SHORTLISTED]: '#84E1BC',
  [ApplicationStage.READY_TO_INTERVIEW]: '#AC94FA',
  [ApplicationStage.INTERVIEW]: '#AC94FA',
  [ApplicationStage.REFERENCE_CHECK]: '#AC94FA',
  [ApplicationStage.READY_TO_OFFER]: '#0E9F6E',
  [ApplicationStage.OFFER]: '#0E9F6E',
  [ApplicationStage.OFFER_ACCEPTED]: '#0E9F6E',
  [ApplicationStage.TO_BE_REJECTED]: '#E02424',
  [ApplicationStage.REJECTED]: '#E02424',
  [ApplicationStage.WITHDRAWN]: '#f39c12',
};

export const CandidateLatestApplicationStages = [
  ApplicationStage.INTERESTED,
  ApplicationStage.APPLIED,
  ApplicationStage.SCREENING,
  ApplicationStage.SHORTLISTED,
  ApplicationStage.INTERVIEW,
  ApplicationStage.REFERENCE_CHECK,
  ApplicationStage.OFFER,
  ApplicationStage.OFFER_ACCEPTED,
  ApplicationStage.REJECTED,
];

export const RecruiterLatestApplicationStages = [
  ApplicationStage.NO_APPLICATIONS,
  ApplicationStage.APPLIED,
  ApplicationStage.SCREENING,
  ApplicationStage.INTERNAL_INTERVIEW,
  ApplicationStage.READY_TO_SHORTLIST,
  ApplicationStage.SHORTLISTED,
  ApplicationStage.READY_TO_INTERVIEW,
  ApplicationStage.INTERVIEW,
  ApplicationStage.REFERENCE_CHECK,
  ApplicationStage.READY_TO_OFFER,
  ApplicationStage.OFFER,
  ApplicationStage.OFFER_ACCEPTED,
  ApplicationStage.REJECTED,
];

export const ClientLatestApplicationStages = [
  ApplicationStage.NO_APPLICATIONS,
  ApplicationStage.APPLIED,
  ApplicationStage.SHORTLISTED,
  ApplicationStage.READY_TO_INTERVIEW,
  ApplicationStage.INTERVIEW,
  ApplicationStage.REFERENCE_CHECK,
  ApplicationStage.OFFER,
  ApplicationStage.OFFER_ACCEPTED,
  ApplicationStage.REJECTED,
];

export const TenantToColumnMap = {
  [TenantType.Candidate]: CandidateLatestApplicationStages,
  [TenantType.Recruiter]: RecruiterLatestApplicationStages,
  [TenantType.Client]: RecruiterLatestApplicationStages,
};

export type BoardConfig = {
  board: { [key: string]: IKanbanColumn<IKanbanJobCard> } | null | undefined;
  boardLoading: boolean;
  boardError: boolean;
  boardEmpty: boolean;
};

const generateBoard = (
  tenant: TenantType,
  jobs: IJob[]
): { [key: string]: IKanbanColumn<IKanbanJobCard> } => {
  // TODO: Merge candidate appplications and jobs
  const jobMap: Record<string, IKanbanColumn<IKanbanJobCard>> = TenantToColumnMap[tenant].reduce(
    (acc, stage: ApplicationStage, index: number) => {
      acc[stage] = {
        id: `${stage}`,
        name: i18n.t(`enums.application_status.${stage}`),
        tasks: [],
        stage,
      };

      return acc;
    },
    {} as { [key: string]: IKanbanColumn<IKanbanJobCard> }
  );

  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < jobs.length; i++) {
    const task: IKanbanJobCard = {
      id: jobs[i].id,
      index: i,
      jobTitle: jobs[i].title,
      type: CardType.Job,
      employerTitle: jobs[i].client_company_name || jobs[i].client?.company_name,
      agencyTitle: jobs[i].recruiter_company_name,
      avatarUrl: jobs[i].client_profile?.company_logo?.public_path,
      hasAttachment: jobs[i].documents_available,
      isLinked: jobs[i].application !== undefined,
      excitementLevel: jobs[i].excitement_rating,
    };

    if (jobMap[jobs[i].latest_application_status]) {
      jobMap[jobs[i].latest_application_status].tasks.push(task);
    }
  }

  return jobMap;
};

export function useGetBoard(
  tenant: TenantType,
  filters: JobFilters | undefined,
  useGlobalJobs = false
) {
  const {
    currentData: myJobs,
    isLoading,
    isError,
  } = useGetMyActiveJobsQuery(filters, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    skip: useGlobalJobs,
  });

  const { currentData: globalJobs } = useGetOrganisationJobsQuery(filters, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    skip: !useGlobalJobs,
  });

  const boardRef = useRef<BoardConfig | null>(null);

  const memoizedValue = useMemo(
    () => {
      const returnValue: BoardConfig = {
        board: (useGlobalJobs ? globalJobs : myJobs)?.results
          ? generateBoard(tenant, ((useGlobalJobs ? globalJobs : myJobs) as JobsResponse).results)
          : boardRef.current?.board,
        boardLoading: isLoading,
        boardError: isError,
        boardEmpty: !isLoading && !(useGlobalJobs ? globalJobs : myJobs)?.count,
      };

      boardRef.current = returnValue;

      return returnValue;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [(useGlobalJobs ? globalJobs : myJobs)?.results, isError, isLoading, tenant]
  );

  return memoizedValue;
}
