import { Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { debounce, isString } from 'lodash';

import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Unstable_Grid2';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Box,
  Alert,
  Stack,
  Button,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import interview from 'src/assets/lottie/interview.json';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { ISubmissionData } from 'src/services/applications/applications.types';
import { hideCandidateJobInviteActionModal } from 'src/store/slices/jobs/jobsSlice';
import {
  IJob,
  LinkBehaviour,
  JobInviteAction,
  JobVerificationStatus,
} from 'src/services/jobs/jobs.types';
import {
  useActionJobInviteMutation,
  useGetOrganisationJobsQuery,
  useGetComplianceQuestionsByJobQuery
} from 'src/services/jobs/jobs.service';

import AnimationContainer from 'src/components/animation/container';
import SelectableCard from 'src/components/selectable-card/selectable-card';
import ComplianceQuestionsForm, { ComplianceFormHandle } from 'src/components/compliance/compliance-form';

import { BusinessErrorCodes } from 'src/types/business-errors';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  open: boolean;
  // Disable the clone or link functionality
  // the job will automatically be cloned when accepted.
  disableCloneOrLink?: boolean;
};

const generateJobInviteAcceptError = (e: any): string => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.UnableToActionInvite) {
      return i18n.t('jobs.invite_modal.api.accept.error.invalid');
    }

    if (e?.data.error_code === BusinessErrorCodes.CandidateAlreadyApplied) {
      return i18n.t('jobs.invite_modal.api.accept.error.exists');
    }
  }

  return i18n.t('jobs.invite_modal.api.accept.error.default');
};

const generateJobInviteRejectError = (e: any): string => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.UnableToActionInvite) {
      return i18n.t('jobs.invite_modal.api.reject.error.invalid');
    }
  }

  return i18n.t('jobs.invite_modal.api.reject.error.default');
};

export default function CandidateJobActionModal({ open, disableCloneOrLink = false }: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const complianceFormRef = useRef<ComplianceFormHandle>(null);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [complianceSubmissions, setComplianceSubmissions] = useState<ISubmissionData[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [linkBehaviour, setLinkBehaviour] = useState<LinkBehaviour>(LinkBehaviour.CLONE_SOURCE);

  const [selectedJob, onSelectJob] = useState<IJob>();

  const dispatch = useAppDispatch();

  const [jobSearchQuery, setJobSearchQuery] = useState('');

  const candidateJobInvite = useAppSelector((state) => state.jobs.candidateJobInvite);

  const { currentData: jobsData } = useGetOrganisationJobsQuery({
    search_query: jobSearchQuery,
    page: 1,
    per_page: 5,
    linked_application: false,
  });

  const { currentData: jobQuestionsData } = useGetComplianceQuestionsByJobQuery({
    jobId: candidateJobInvite?.job.id as string,
  }, {
    skip: !candidateJobInvite,
  });

  const [actionRequest, { isLoading: isSubmitting }] = useActionJobInviteMutation();

  const onClose = () => {
    setCurrentPage(0);
    dispatch(hideCandidateJobInviteActionModal());
  };

  const onNextPage = () => {
    if (jobQuestionsData?.length) {
      setCurrentPage(1);
    }
    else {
      if (disableCloneOrLink) {
        onAcceptRequest();
        return;
      }

      setCurrentPage(2);
    }
  };

  const onRejectRequest = async () => {
    setErrorMessage(null);

    if (!candidateJobInvite) return;

    try {
      await actionRequest({
        requestId: candidateJobInvite.id,
        data: {
          action: JobInviteAction.REJECT,
        },
      }).unwrap();

      onClose();

      enqueueSnackbar(t('jobs.invite_modal.api.reject.success'));
    } catch (e) {
      console.error(e);

      enqueueSnackbar(generateJobInviteRejectError(e), { variant: 'error' });
    }
  };

  const onCompleteComplianceSubmission = async () => {
    if (!complianceFormRef.current) return;

    const isValid = await complianceFormRef.current.validate();

    if (!isValid) {
      return;
    }

    const submissions = await complianceFormRef.current?.getSubmissions();
    setComplianceSubmissions(submissions);

    if (disableCloneOrLink) {
      onAcceptRequest();
      return;
    }

    setCurrentPage(2);
  }

  const onAcceptRequest = async () => {
    if (!candidateJobInvite) return;

    const isLinkExisting = linkBehaviour === LinkBehaviour.LINK_EXISTING;

    setErrorMessage(null);

    try {
      await actionRequest({
        requestId: candidateJobInvite.id,
        data: {
          action: JobInviteAction.ACCEPT,
          link_settings: {
            job_id: selectedJob && isLinkExisting ? selectedJob.id : undefined,
            link_behaviour: linkBehaviour,
          },
          compliance_submissions: complianceSubmissions
        },
      }).unwrap();
      enqueueSnackbar(
        t(`jobs.invite_modal.api.accept.success.${isLinkExisting ? 'link' : 'clone'}`, {
          role: selectedJob?.title,
        })
      );

      setErrorMessage(
        t(`jobs.invite_modal.api.accept.success.${isLinkExisting ? 'link' : 'clone'}`, {
          role: selectedJob?.title,
        })
      );

      onClose();
    } catch (e) {
      console.error(e);
      enqueueSnackbar(generateJobInviteAcceptError(e), { variant: 'error' });
    }
  };

  const onSearchJobs = debounce((input: string) => {
    setJobSearchQuery(input);
  }, 500);

  const renderRequest = (
    <Grid container gap={2}>
      <Grid xs={12}>
        <Box>
          <AnimationContainer height={250} json={interview} loop />
        </Box>
      </Grid>

      <Grid xs={12} rowGap={1}>
        <Grid xs={12}>
          <Typography textAlign="center">
            <Trans>
              {t('jobs.invite_modal.alert', {
                role: candidateJobInvite?.job.title,
                company: candidateJobInvite?.initiator.company_name,
              })}
            </Trans>
          </Typography>
        </Grid>

        <Grid xs={12} flexDirection="row" spacing={2} py={2}>
          <Stack direction="row" gap={2}>
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              variant="outlined"
              onClick={onRejectRequest}
            >
              {t('common.decline')}
            </LoadingButton>

            <LoadingButton
              onClick={onNextPage}
              loading={isSubmitting}
              variant="contained"
              color="primary"
              fullWidth
            >
              {t('common.accept')}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderComplianceView = (
    <Stack direction="column">
      <Grid container gap={2}>
        <Grid xs={12}>
          <Typography variant="h6">{t('jobs.invite_modal.compliance_title')}</Typography>
        </Grid>
        <Grid xs={12}>
          <ComplianceQuestionsForm questions={jobQuestionsData ?? []} ref={complianceFormRef} />
        </Grid>
      </Grid>
    </Stack>
  )

  const renderCloner = (
    <Stack direction="column">
      <Grid container gap={2}>
        <Grid xs={12}>
          <SelectableCard
            title={t('jobs.action.select_job.clone')}
            subtitle={t('jobs.action.select_job.clone_info')}
            selected={linkBehaviour === LinkBehaviour.CLONE_SOURCE}
            onClick={() => setLinkBehaviour(LinkBehaviour.CLONE_SOURCE)}
            flexDirection="row"
          />
        </Grid>
        <Grid xs={12}>
          <SelectableCard
            title={t('jobs.action.select_job.select')}
            subtitle={t('jobs.invite_modal.clone')}
            selected={linkBehaviour === LinkBehaviour.LINK_EXISTING}
            onClick={() => setLinkBehaviour(LinkBehaviour.LINK_EXISTING)}
            flexDirection={linkBehaviour === LinkBehaviour.LINK_EXISTING ? 'column' : 'row'}
          >
            {linkBehaviour === LinkBehaviour.LINK_EXISTING && (
              <Stack sx={{ pt: 2 }}>
                <Autocomplete
                  id="job-select"
                  onChange={(_e: any, value) => value && onSelectJob(value)}
                  options={jobsData?.results ?? []}
                  isOptionEqualToValue={(option: IJob, value: IJob) => option.id === value.id}
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        disabled={option.verification_status !== JobVerificationStatus.UNVERIFIED}
                      />
                      {option.title}
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField {...params} label={t('common.jobs')} placeholder="Favorites" />
                  )}
                  onInputChange={(_e: any, value: string) => onSearchJobs(value)}
                />
              </Stack>
            )}
          </SelectableCard>
        </Grid>
      </Grid>
    </Stack>
  );

  const renderCurrentPage = () => {
    if (currentPage === 0) {
      return renderRequest;
    }

    if (currentPage === 1) {
      return renderComplianceView;
    }

    return renderCloner;
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>{t(`jobs.invite_modal.title`)}</DialogTitle>

      <DialogContent>
        {errorMessage && (
          <Alert variant="outlined" severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}

        {renderCurrentPage()}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
        {currentPage === 1 && (
          <LoadingButton
            onClick={onCompleteComplianceSubmission}
            variant="contained"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('common.next')}
          </LoadingButton>
        )}

        {currentPage === 2 && (
          <LoadingButton
            onClick={onAcceptRequest}
            variant="contained"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('jobs.action.link_job')}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
