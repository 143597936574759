import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';

import {
  Box,
  Card,
  Stack,
  TextField,
  Typography,
  CardContent
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { IJobQuestion } from 'src/services/jobs/jobs.types';
import { ISubmissionData } from 'src/services/applications/applications.types';

interface FormData {
  [key: string]: {
    text: string;
  };
}

interface Props {
  questions: IJobQuestion[];
}

export interface ComplianceFormHandle {
  validate: () => Promise<boolean>;
  getSubmissions: () => Promise<ISubmissionData[]>;
  reset: () => void;
}

const ComplianceQuestionsForm = forwardRef<ComplianceFormHandle, Props>(({ questions }, ref) => {

  const { t } = useTranslate();

  // dynamically create the validation schema based on questions
  const validationSchema = React.useMemo(() => {
    const schemaShape = questions.reduce((acc, question) => {
      acc[question.id] = yup.object().shape({
        text: question.required
          ? yup.string().required('This question is required')
          : yup.string()
      });
      return acc;
    }, {} as { [key: string]: any });

    return yup.object().shape(schemaShape);
  }, [questions]);

  const methods = useForm<FormData>({
    defaultValues: questions.reduce((acc, question) => ({
      ...acc,
      [question.id]: { text: '' }
    }), {}),
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });

  const { control, trigger, getValues, formState: { errors } } = methods;

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const isValid = await trigger();
      return isValid;
    },
    getSubmissions: async () => {
      const formData = getValues();
      return Object.entries(formData).map(([questionId, value]) => ({
        question_id: questionId,
        submission_data: value
      }));
    },
    reset: () => methods.reset()
  }));

  if (!questions.length) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Card>
        <CardContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack spacing={3}>
              {questions.map((question) => (
                <Box key={question.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      mb: 1
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>
                      {question.question.text}
                      {question.required && (
                        <Typography
                          component="span"
                          color="error"
                          sx={{ ml: 0.5 }}
                        >
                          *
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                  <Controller
                    name={`${question.id}.text`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        multiline
                        rows={2}
                        placeholder="Enter your answer"
                        error={!!errors[question.id]?.text}
                        helperText={errors[question.id]?.text?.message}
                        sx={{ mt: 1 }}
                      />
                    )}
                  />
                </Box>
              ))}
            </Stack>
          </form>
        </CardContent>
      </Card>
    </FormProvider>
  );
});

ComplianceQuestionsForm.displayName = 'ComplianceQuestionsForm';

export default ComplianceQuestionsForm;