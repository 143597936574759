import Lottie from "react-lottie-player";

type Props = {
  json: any;
  loop: boolean;
  height?: number | string;
  width?: number | string;
};

export default function AnimationContainer({
  json,
  height = '100%',
  width = '100%',
  loop = true,
}: Props) {
  return (
    <Lottie
      loop={loop}
      animationData={json}
      play
      style={{ width, height }}
    />
  );
}
