import { enqueueSnackbar } from 'notistack';
import { Fragment, useState, useCallback } from 'react';

import Stack from '@mui/system/Stack';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Button, IconButton } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { JobInvite } from 'src/services/jobs/jobs.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { isOrganisationTenant } from 'src/services/auth/auth.utils';
import { ConnectionTarget } from 'src/services/connections/connections.types';
import { hidePendingCandidateInvitesModal } from 'src/store/slices/jobs/jobsSlice';
import {
  useCancelJobInviteMutation,
  useGetOutgoingCandidateInvitesQuery,
} from 'src/services/jobs/jobs.service';

import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';

type Props = {
  open: boolean;
  scopedJobId?: string;
};

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 5,
};

export default function PendingCandidateInviteModal({ open, scopedJobId }: Props) {
  const { t } = useTranslate();

  const [target] = useState<ConnectionTarget>(ConnectionTarget.Incoming);

  const dispatch = useAppDispatch();

  const tenant = useOrgTenant();

  const onClose = useCallback(() => {
    dispatch(hidePendingCandidateInvitesModal());
  }, [dispatch]);

  const [pageOptions, setPageOptions] = useState<PageOptions>(DEFAULT_PAGE);

  const { currentData: inviteData } = useGetOutgoingCandidateInvitesQuery(
    {
      job_id: scopedJobId || undefined,
      ...pageOptions,
    },
    { skip: !isOrganisationTenant(tenant) }
  );

  const [cancelInvite, { isLoading: isCancelling }] = useCancelJobInviteMutation();

  const changePage = useCallback(
    (page: number) => {
      setPageOptions((prev) => ({
        ...prev,
        page: page + 1,
      }));
    },
    [setPageOptions]
  );

  const handleCancelInvite = useCallback(
    async (inviteId: string) => {
      try {
        await cancelInvite(inviteId);

        enqueueSnackbar(t('jobs.pending_invite_modal.api.cancel.success'), { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(t('jobs.pending_invite_modal.api.cancel.default_error'), {
          variant: 'error',
        });
      }
    },
    [cancelInvite, t]
  );

  const renderRequest = (request: JobInvite, index: number) => {
    const jobTitle = request.job?.title;
    const candidateName = `${request.first_name} ${request.last_name}`;
    const candidateEmail = request.email;

    return (
      <Fragment key={`request-${index}`}>
        <ListItem sx={{ mb: 1 }}>
          <ListItemAvatar>
            <Avatar>{candidateName?.charAt(0)}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={jobTitle}
            primaryTypographyProps={{ sx: { textTransform: 'capitalize', fontWeight: 500 } }}
            secondary={`${candidateName} - ${candidateEmail}`}
            secondaryTypographyProps={{
              paddingRight: 14,
              sx: { textTransform: 'capitalize', fontWeight: 400, fontSize: 13 },
            }}
          />
          <IconButton onClick={() => handleCancelInvite(request.id)} disabled={isCancelling}>
            <Iconify icon="gg:trash" color="red" />
          </IconButton>
        </ListItem>

        <Divider variant="middle" component="li" />
      </Fragment>
    );
  };

  const renderInvites = (
    <Stack direction="column">
      <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
        {t(`jobs.pending_invite_modal.alert`)}
      </Alert>

      {inviteData?.count ? (
        <List dense>
          {inviteData?.results?.map((requestObj, requestIndex) =>
            renderRequest(requestObj, requestIndex)
          )}
        </List>
      ) : (
        <EmptyContent
          filled
          title="No Data"
          sx={{
            py: 10,
          }}
        />
      )}

      <Stack flexDirection="row" justifyContent="center" sx={{ pt: 2 }}>
        <Pagination
          page={pageOptions.page ?? 1}
          count={Math.ceil((inviteData?.count ?? 1) / pageOptions.per_page)}
          onChange={(_e, value) => changePage(value)}
        />
      </Stack>
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>
        {t('jobs.pending_invite_modal.title', {
          target: target === 'incoming' ? t('common.incoming') : t('common.outgoing'),
        })}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>{renderInvites}</Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
