import LockIcon from '@mui/icons-material/Lock';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Card,
  Paper,
  Stack,
  Skeleton,
  Typography,
  CardContent
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { NoPermissionSVG, EmptySubmissionSVG } from 'src/theme/illustrations';
import { useGetApplicationComplianceSubmissionsQuery } from 'src/services/applications/applications.service';

type Props = {
  applicationId: string;
  jobId: string;
};

export default function ApplicationComplianceSubmissionView({ applicationId, jobId }: Props) {

  const { t } = useTranslate();

  const { data: complianceSubmissions, isLoading, error } = useGetApplicationComplianceSubmissionsQuery(
    {
      applicationId,
      jobId,
    },
    {
      skip: !applicationId || !jobId,
    }
  );

  // loading state
  if (isLoading) {
    return (
      <Card>
        <CardContent>
          <Skeleton variant="text" width={200} height={32} sx={{ mb: 3 }} />
          {[1, 2, 3].map((i) => (
            <Box key={i} sx={{ mb: 2 }}>
              <Skeleton variant="text" width={150} />
              <Skeleton variant="rectangular" height={80} sx={{ mt: 1 }} />
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  }

  // error state (403)
  if (error && typeof error === 'object' && 'status' in error && error.status === 403) {
    return (
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" py={4}>
            <NoPermissionSVG style={{ width: '180px', height: '180px' }} />
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LockIcon fontSize="small" />
                {t('applications.detail.compliance_submissions.no_permission_title')}
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={1}>
                {t('applications.detail.compliance_submissions.no_permission_desc')}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }

  // empty state
  if (!complianceSubmissions || complianceSubmissions.length === 0) {
    return (
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" py={4}>
            <EmptySubmissionSVG style={{ width: '180px', height: '180px' }} />
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <HelpOutlineIcon fontSize="small" />
                {t('applications.detail.compliance_submissions.no_submissions_title')}
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={1}>
                {t('applications.detail.compliance_submissions.no_submissions_desc')}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }

  // data list display
  return (
    <Stack spacing={2}>
      {complianceSubmissions.map((submission) => (
        <Paper
          key={submission.id}
          elevation={2}
          variant="outlined"
          sx={{
            p: 2,
            bgcolor: '#fbfbfb',
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        >
          <Box sx={{ mb: 1 }}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Typography variant="subtitle2">
                {submission.question.question.text}
                {submission.question.required && (
                  <Typography
                    component="span"
                    color="error"
                    sx={{ ml: 0.5 }}
                  >
                    *
                  </Typography>
                )}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ userSelect: 'none' }}>
            {submission.answer.text}
          </Typography>
        </Paper>
      ))}
    </Stack>
  );
}