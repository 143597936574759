import { SvgIcon, SvgIconProps } from '@mui/material';

export const NoPermissionSVG = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 200 200"
    {...props}
  >
    <rect width="200" height="200" fill="none" />
    <path d="M60 80h80v80H60z" fill="#f3f4f6" />
    <circle cx="100" cy="120" r="15" fill="#d1d5db" />
    <path d="M85 80c0-8.3 6.7-15 15-15s15 6.7 15 15" fill="none" stroke="#d1d5db" strokeWidth="10" />
    <path d="M70 80v-10c0-16.6 13.4-30 30-30s30 13.4 30 30v10" fill="none" stroke="#e5e7eb" strokeWidth="10" />
  </SvgIcon>
);

export const EmptySubmissionSVG = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 200 200"
    {...props}
  >
    <rect width="200" height="200" fill="none" />
    <path d="M40 80h120v80H40z" fill="#f3f4f6" />
    <path d="M70 50h60v30H70z" fill="#e5e7eb" />
    <path d="M85 100h30v10H85zM60 120h80v10H60zM70 140h60v10H70z" fill="#d1d5db" />
    <path d="M160 80H40l20-30h80z" fill="#e5e7eb" />
  </SvgIcon>
);