import { useRef, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/system/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import BoltIcon from '@mui/icons-material/Bolt';
import InfoIcon from '@mui/icons-material/Info';
import PaidIcon from '@mui/icons-material/Paid';
import WorkIcon from '@mui/icons-material/Work';
import LinkIcon from '@mui/icons-material/Link';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import { Tooltip, IconButton } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/system';
import SettingsIcon from '@mui/icons-material/Settings';

import i18n from 'src/locales/i18n';
import { CandidateProfile } from 'src/services/candidates/candidates.types';

import ProfileBio from './profile-bio';
import ProfileLinksEditor from './profile-links';
import ProfileSkillsEditor from './profile-skills';
import ProfileWorkHistory from './profile-work-history';
import ProfileSalaryEditor from './profile-salary-editor';
import ProfileCurrentInformation from './profile-basic-info';
import WorkPreferencesEditor from './work-preferences-editor';
import ProfileEducationHistory from './profile-education-history';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  showNavigation?: boolean;
  mode?: 'edit' | 'view';
};

// Define section type
interface Section {
  id: string;
  label: string;
  icon: React.ReactNode;
}

// Styled components
const NavButton = styled(Button)(({ theme }) => ({
  justifyContent: 'flex-start',
  padding: theme.spacing(1.5, 2),
  marginBottom: theme.spacing(1),
  width: '100%',
  transition: theme.transitions.create(['background-color', 'transform'], {
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    transform: 'translateX(8px)',
  },
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
  },
}));

const NavigationContainer = styled(Paper)(({ theme }) => ({
  position: 'sticky',
  left: theme.spacing(2),
  top: '100px',
  padding: theme.spacing(2),
  zIndex: 1100,
}));

// Define sections
const sections: Section[] = [
  { id: 'bio', label: i18n.t('profile.sections.summary.title'), icon: <PersonIcon /> },
  { id: 'skills', label: i18n.t('profile.sections.skills.title'), icon: <BoltIcon /> },
  { id: 'work', label: i18n.t('profile.sections.work_history.title'), icon: <WorkIcon /> },
  { id: 'education', label: i18n.t('profile.sections.education_history.title'), icon: <SchoolIcon /> },
  { id: 'current', label: i18n.t('profile.sections.information.title'), icon: <InfoIcon /> },
  { id: 'preferences', label: i18n.t('profile.sections.work_preferences.title'), icon: <SettingsIcon /> },
  { id: 'salary', label: i18n.t('profile.sections.salary.title'), icon: <PaidIcon /> },
  { id: 'links', label: i18n.t('profile.sections.links.title'), icon: <LinkIcon /> },
];

const ProfileNavigation: React.FC = () => {
  const [activeSection, setActiveSection] = useState('bio');
  const isScrollingRef = useRef<boolean>(false);
  const scrollTimeoutRef = useRef<NodeJS.Timeout>();

  const handleClick = (sectionId: string) => {
    setActiveSection(sectionId);
    const element = document.getElementById(sectionId);
    if (element) {

      isScrollingRef.current = true;

      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth'
      });

      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }

      // Reset the flag after scrolling animation completes
      scrollTimeoutRef.current = setTimeout(() => {
        isScrollingRef.current = false;
      }, 1000);
    }
  };

  useEffect(() => {
    const handleScroll = () => {

      if (isScrollingRef.current) return;

      const scrollPosition = window.scrollY + 100;

      // find the section that is currently in view
      const currentSection = sections.find(section => {
        const element = document.getElementById(section.id);
        if (element) {
          const { offsetTop, offsetHeight } = element;
          return scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight;
        }
        return false;
      });

      if (currentSection) {
        setActiveSection(currentSection.id);
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Initial check
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <NavigationContainer elevation={3}>
      <Stack spacing={1}>
        {sections.map(({ id, label, icon }) => (
          <Tooltip key={id} title={label} placement="left">
            <IconButton
              key={id}
              onClick={() => handleClick(id)}
              color={activeSection === id ? 'primary' : 'inherit'}
            >
              {icon}
            </IconButton>
          </Tooltip>
        ))}
      </Stack>
    </NavigationContainer>
  );
};

const CandidateProfileEditor: React.FC<Props> = ({ profile, isLoading, mode = 'edit', showNavigation = true }: Props) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', alignItems: 'start', position: 'relative', gap: 2 }}>

      {
        (!isMobile && showNavigation) && <ProfileNavigation />
      }

      <Stack spacing={3} sx={{ width: '100%' }}>
        <Box id="bio">
          <ProfileBio profile={profile} isLoading={isLoading} mode={mode} />
        </Box>

        <Box id="skills">
          <ProfileSkillsEditor profile={profile} isLoading={isLoading} mode={mode} />
        </Box>

        <Box id="work">
          <ProfileWorkHistory profile={profile} isLoading={isLoading} mode={mode} />
        </Box>

        <Box id="education">
          <ProfileEducationHistory profile={profile} isLoading={isLoading} mode={mode} />
        </Box>

        <Box id="current">
          <ProfileCurrentInformation profile={profile} isLoading={isLoading} mode={mode} />
        </Box>

        <Box id="preferences">
          <WorkPreferencesEditor profile={profile} isLoading={isLoading} mode={mode} />
        </Box>

        <Box id="salary">
          <ProfileSalaryEditor profile={profile} isLoading={isLoading} mode={mode} />
        </Box>

        <Box id="links">
          <ProfileLinksEditor profile={profile} isLoading={isLoading} mode={mode} />
        </Box>
      </Stack>
    </Box>
  );
};

export default CandidateProfileEditor;